@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

@import "@continis/theme/src/tailwind/base.css";
@import "@continis/theme/src/tailwind/components.css";
@import "@continis/theme/src/tailwind/utilities.css";

@import "tom-select/dist/css/tom-select.css";

.responsive-object {
  position: relative;
}

.responsive-object iframe,
.responsive-object object,
.responsive-object embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

p:empty {
  display: none;
}

.richtext-image.left {
  @apply inline my-2 sm:float-left sm:mr-2;
}

.richtext-image.right {
  @apply inline my-2 sm:float-right sm:ml-2;
}
